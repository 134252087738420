import colorFns from 'colorFns';
import CircleCheckIcon from 'components/CircleCheckIcon';
import { REJECTED_STATUSES, VenueCardPopover } from 'components/VenueCard';
import CloseIcon from 'images/icons/close.svg?react';
import MapIcon from 'images/icons/map.svg?react';
import PlusIcon from 'images/icons/plus.svg?react';
import VenuePin from 'images/icons/venue_pin.svg?react';
import React, { useCallback, useMemo, useRef } from 'react';
import ScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';
import { OnlySingleLine } from 'shared';
import styled from 'styled-components';
import { Column, Row } from 'ui';
import TileInfo, { tileTextPropsByFormat } from './TileInfo';
import { TPillTypes } from './VenuePill';

export type TTileFormats = 'tile' | 'thumb' | 'default' | 'proposalThumb' | 'proposal' | 'featured';

type TBorderWrapperProps = {
    width?: string;
    minWidth?: string;
    clickable?: boolean;
    centered?: boolean;
};

const BorderWrapper = styled.div<TBorderWrapperProps>`
    position: relative;
    border: 1px solid;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.preferredRank40)};
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;

    ${props =>
        props.centered &&
        `
    align-items: center;
    justify-content: center;
    `}

    ${props =>
        props.width &&
        `
    width: ${props.width};
    min-width: ${props.width};
    max-width: ${props.width};
    `}
    ${props =>
        props.minWidth &&
        `
    min-width: ${props.minWidth};
    `}

    ${props =>
        props.clickable &&
        `
    cursor: pointer;
    border-style: dashed;
    `}
`;

const TileWrapper = styled.div.attrs(() => ({
    'data-testid': 'venue-thumb',
}))<{ padding?: string; fullHeight?: boolean }>`
    ${props =>
        props.fullHeight &&
        `
        height: 100%;
    `}

    ${props =>
        props.padding &&
        `
        padding: ${props.padding};
    `}
`;

export const FixedRatio = styled.div<{ backgroundColor?: Themed.Color; fixedRatio?: string }>`
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;

    ${({ backgroundColor, theme: { getColor, EColors } }) =>
        `background-color: ${backgroundColor || getColor(EColors.softAccentedBackground)};`}
    border-radius: 4px;

    ${props =>
        props.fixedRatio
            ? `
        padding-top: ${props.fixedRatio};
    `
            : `
        border: dashed 1px ${props.theme.getColor(props.theme.EColors.preferredRank40)};
        display: flex;
        justify-content: center;
        align-items: center;
    `}

    svg {
        fill: transparent;
    }
`;

type RoundedImageProps = {
    url?: string;
    width: string | number;
    height: string | number;
    fixedRatio: string;
    highlighted?: boolean;
    onClick?: (venueId: number) => void;
};

const RoundedImage = styled(FixedRatio)<Partial<RoundedImageProps>>`
    background-image: url(${props => props.url});
    background-size: cover;
    background-position: center;

    ${props =>
        props.onClick
            ? `
    cursor: pointer;
    `
            : ``}

    ${({ highlighted, theme: { getColor, EColors } }) =>
        highlighted &&
        `
        transform: scale(1.1);
        border: 4px solid;
        border-color: ${getColor(EColors.primaryAction)};
    `}
`;

const TileContent = styled(Row)<{ padding?: number }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: ${props => props.padding || 0}px;
    box-sizing: border-box;

    & > * {
        height: 100%;
    }
`;

const FadedOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.lightGrey)};
    opacity: 0.7;
    border-radius: 3px;
    pointer-events: none;
`;

const BasicsCaps = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkerGrey)};
`;
const Subtext = styled.div`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey2)};
`;

const Title = styled(Row)`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.darkestGrey)};
    height: 2.5em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
`;

// const TitleText = ({ children }: { children: React.ReactNode }) => (
//     <Row>
//         <Title>{children}</Title>
//     </Row>
// );

const TileText = styled('div').attrs<{ format: TTileFormats }>(({ format = 'default' }) => ({ format }))`
    display: flex;
    flex-direction: column;
    padding: 0 1em 0.5em;

    ${({ format }) => {
        switch (format) {
            case 'tile':
                return `
                    margin-top: 0.5em;
                `;
            case 'proposalThumb':
                return `
                    margin-top: 1em;
                `;
            case 'proposal':
                return `
                    margin-top: 0.75em;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 1em;
                `;
        }
    }}

    ${Title} {
        ${({ format }) => {
            switch (format) {
                case 'tile':
                    return `
            font-size: 0.625rem;
            font-weight: 500;
            letter-spacing: -0.09px;
        `;
                case 'proposal':
                case 'featured':
                    return `
            font-size: 1.125em;
            font-weight: 400;
            line-height: 1.3;
        `;
                case 'thumb':
                case 'default':
                case 'proposalThumb':
                default:
                    return `
            font-size: 0.9375em;
            font-weight: 500;
            letter-spacing: -0.4px;
            `;
            }
        }}
    }

    ${Subtext} {
        ${({ format }) => {
            switch (format) {
                case 'tile':
                case 'proposalThumb':
                    return `
                        font-size: 0.9375em;
                        font-weight: 400;
                        letter-spacing: normal;
                        ${OnlySingleLine}
                    `;
                case 'proposal':
                    return `
                        font-size: 0.9375em;
                        line-height: 1;
                        text-align: right;
                        min-height: 0.9375em;
                    `;
                case 'featured':
                    return `
                        font-size: 1.125em;
                        font-weight: normal;
                        line-height: 1.5;
                        letter-spacing: normal;
                        margin-top: 0.5em;
                        min-height: 1.125em;
                    `;
                case 'thumb':
                case 'default':
                default:
                    return ``;
            }
        }}
    }

    ${BasicsCaps} {
        ${OnlySingleLine}

        ${({ format }) => {
            switch (format) {
                case 'proposal':
                case 'featured':
                    return `
                        margin-top: 0.3em;
                        font-size: 0.9375em;
                        ${format === 'featured' ? `margin-bottom: 0.75em;` : ``}
                    `;
                case 'tile':
                case 'thumb':
                case 'default':
                case 'proposalThumb':
                default:
                    return `
            font-size: 0.9375em;
            font-weight: 400;
            letter-spacing: normal;
            margin-top: 0.25em;
        `;
            }
        }}
    }
`;

const TileButtons = styled(Column)`
    flex: 0 0 auto;
    justify-content: space-between;

    min-width: 28px;
    margin-left: 8px;

    z-index: 5;
`;
const PropagationBlocker = styled(Row).attrs(() => ({
    onClick: (e: React.SyntheticEvent) => e.stopPropagation(),
}))``;
const TopIcons = styled(PropagationBlocker)`
    display: flex;
    flex: 0 0 auto;
    min-height: 0;
    align-items: flex-start;
`;
const Blank = styled(Row)`
    flex: 1 0 0;
`;
const BottomIcons = styled(PropagationBlocker)`
    display: flex;
    flex: 0 0 auto;
    min-height: 0;
    align-items: flex-end;
`;
type TClickableProps = {
    onClick?: (e: React.SyntheticEvent) => void;
};
const VenueAddCircleIcon = styled(PlusIcon)<TClickableProps>`
    width: 12px;
    height: 12px;
    padding: 7px;
    border: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-radius: 14px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    flex: 0 0 auto;

    ${props => (props.onClick ? `cursor: pointer;` : ``)}

    opacity: 0;
    transition: 0.2s all ease-out;

    ${TileContent}:hover & {
        opacity: 1;
    }
`;

export const VenueSelectedIcon = CircleCheckIcon;

const VenueCloseIcon = styled(CloseIcon)`
    color: ${colorFns.pureWhite};
`;

const Map = styled(MapIcon)`
    color: ${colorFns.tilePinIcon};
    fill: transparent;
    position: absolute;
`;
const Pin = styled(VenuePin)`
    color: ${colorFns.tilePinIcon};
    position: absolute;
`;
const Plus = styled(PlusIcon)`
    color: ${colorFns.tilePinIcon};
    position: absolute;
`;
type TVenueTileEmptyProps = {
    width: string;
    minWidth: string;
    fixedRatio: string;
    withPin: boolean;
    withMap: boolean;
    addNew: boolean;
    backgroundColor: Themed.Color;
    padding: string;
    fullHeight: boolean;
    onClick?: React.MouseEventHandler;
};
export function VenueTileEmpty({
    width,
    minWidth,
    fixedRatio,
    withPin,
    withMap,
    addNew,
    backgroundColor,
    ...props
}: Partial<TVenueTileEmptyProps>) {
    return (
        <TileWrapper {...props}>
            <BorderWrapper width={width} minWidth={minWidth} clickable={!!addNew} centered>
                <FixedRatio fixedRatio={fixedRatio} backgroundColor={backgroundColor} />
                {withMap && <Map />}
                {addNew && <Plus />}
                {withPin && <Pin />}
            </BorderWrapper>
        </TileWrapper>
    );
}

const TILE_PROPS_BY_FORMAT: {
    [key in TTileFormats]: {
        padding: number;
        imgTransform?: string;
    };
} & {
    undefined: {
        padding: number;
        imgTransform?: string;
    };
} = {
    tile: {
        padding: 12,
        imgTransform: 'w_300,q_auto:best',
    },
    thumb: {
        padding: 20,
        imgTransform: 'w_400,q_auto:best',
    },
    proposalThumb: {
        padding: 24,
        imgTransform: 'w_600,q_auto:best',
    },
    proposal: {
        padding: 24,
    },
    featured: {
        padding: 48,
    },
    default: {
        padding: 24,
        imgTransform: 'w_500,q_auto:best',
    },
    // interestingly this will work if we did tilePropsByFormat[undefined] or tilePropsByFormat.undefined
    // because key access coerces undefined to 'undefined'
    undefined: {
        padding: 24,
        imgTransform: 'w_500,q_auto:best',
    },
};

type TActions = {
    selected?: boolean;
    menuOptions?: { label: string; handler: () => void }[];

    hideButtons?: boolean;
    onSelect?: (venue: Bizly.Venue) => void;
    onDeselect?: (venue: Bizly.Venue) => void;
    onClose?: () => void;
};

export type VenueTileProps = {
    className?: string;
    venue: Bizly.Venue;
    format?: TTileFormats;
    width?: string;
    minWidth?: string;
    fixedRatio: string;
    padding?: string;
    highlighted?: boolean;

    pillType?: TPillTypes;

    disableRejected?: boolean;
    onClick?: (venueId: number) => void;
    onMouseOver?: (venueId: number) => void;
    onMouseLeave?: () => void;

    enableView?: boolean;
};

type TBaseVenueTile = VenueTileProps & TActions;

export function BaseVenueTile({
    venue,
    format,
    width,
    minWidth,
    fixedRatio,
    padding,
    highlighted,

    selected = false,
    disableRejected = false,
    menuOptions = [],
    pillType,

    onClick,
    onMouseOver,
    onMouseLeave,

    hideButtons,
    onSelect,
    onDeselect,
    onClose,

    enableView,
}: TBaseVenueTile) {
    const tileRef = useRef<HTMLDivElement>(null);
    const { padding: contentPadding, imgTransform } = useMemo(
        () => TILE_PROPS_BY_FORMAT[format || 'undefined'],
        [format]
    );

    const disabled = disableRejected && REJECTED_STATUSES.has(venue.status);

    const { title } = useMemo(() => tileTextPropsByFormat(venue, format), [format, venue]);

    const canSelect = !disabled && !(venue.externalUrl || venue.type.id === 10) && onSelect;

    const onSelectVenue = useCallback(() => !disabled && onSelect && onSelect(venue), [onSelect, venue, disabled]);
    const onDeselectVenue = useCallback(
        () => !disabled && onDeselect && onDeselect(venue),
        [onDeselect, venue, disabled]
    );

    const mouseover = useCallback(
        () => !disabled && onMouseOver && onMouseOver(venue.id),
        [onMouseOver, venue.id, disabled]
    );
    const mouseleave = useCallback(() => !disabled && onMouseLeave && onMouseLeave(), [onMouseLeave, disabled]);

    return (
        <TileWrapper padding={padding} ref={tileRef}>
            {highlighted && (
                <ScrollIntoViewIfNeeded
                    options={{
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                        scrollMode: 'if-needed',
                    }}
                >
                    <span />
                </ScrollIntoViewIfNeeded>
            )}
            <BorderWrapper width={width} minWidth={minWidth}>
                <RoundedImage
                    url={
                        imgTransform
                            ? venue.imageUrl.replace('image/upload/', `image/upload/${imgTransform}/`)
                            : venue.imageUrl
                    }
                    onClick={(enableView || !disabled) && onClick ? () => onClick(venue.id) : undefined}
                    onMouseOver={mouseover}
                    onMouseLeave={mouseleave}
                    fixedRatio={fixedRatio}
                    highlighted={highlighted}
                >
                    <TileContent padding={contentPadding}>
                        <TileInfo venue={venue} format={format} pillType={disabled ? 'inquiryStatus' : pillType} />
                        {!hideButtons && (
                            <TileButtons>
                                <TopIcons>{onClose && <VenueCloseIcon onClick={onClose} />}</TopIcons>
                                <Blank />
                                <BottomIcons>
                                    {!!menuOptions.length && <VenueCardPopover options={menuOptions} />}
                                    {selected ? (
                                        <VenueSelectedIcon onClick={onDeselect && onDeselectVenue} />
                                    ) : (
                                        canSelect && <VenueAddCircleIcon onClick={onSelectVenue} />
                                    )}
                                </BottomIcons>
                            </TileButtons>
                        )}
                    </TileContent>
                    {REJECTED_STATUSES.has(venue.status) && <FadedOverlay />}
                </RoundedImage>
                <TileText format={format}>
                    {title && <Title>{title}</Title>}
                    {/* <div>
                        {title && <TitleText>{title}</TitleText>}
                        {(format === 'proposalThumb' || format === 'proposal') && <BasicsCaps>{caps}</BasicsCaps>}
                    </div>
                    {(format === 'proposalThumb' || format === 'proposal') && (
                        <div>{subtext && <Subtext>{subtext}</Subtext>}</div>
                    )} */}
                </TileText>
            </BorderWrapper>
        </TileWrapper>
    );
}

type TVenueTileWithActions = Omit<TBaseVenueTile, 'hideButtons'>;
type TVenueTileWithoutActions = VenueTileProps;

export default function VenueTile(props: TVenueTileWithActions) {
    return <BaseVenueTile {...props} />;
}
export const VenueTileWithoutActions = (props: TVenueTileWithoutActions) => <BaseVenueTile {...props} hideButtons />;
