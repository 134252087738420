import { Box, IconButton, styled, TextField, Typography } from '@mui/material';
import {
    DataGridPro,
    GridColDef,
    GridDeleteIcon,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridSearchIcon,
} from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Button } from 'ui';

const StyledDataGridPro = styled(DataGridPro)(({ theme }) => ({
    flexGrow: 1,

    '&.MuiDataGrid-root': {
        border: 'none',
        maxHeight: '100%',
        width: '100%',
    },
    '.MuiDataGrid-cell': {
        border: 'none',
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
    },
    '& .MuiToggleButtonGroup-root': {
        margin: theme.spacing(0.5, 0),
    },
    '& .MuiDataGrid-columnHeader': {
        borderRight: 'none',
        backgroundColor: theme.palette.grey[100],

        '&:focus-within': {
            outline: 'none',
            border: 'none',
        },
    },
    '& .MuiDataGrid-cellCheckbox': {
        border: 'none',
        '&:focus-within': {
            outline: 'none',
            border: 'none',
        },
    },
    '& .MuiDataGrid-overlayWrapper': {
        height: 'auto',
    },
    '& .MuiDataGrid-main': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
    },
    '& .MuiDataGrid-footerContainer': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderTopWidth: 0,
    },
    '& .MuiDataGrid-row': {
        '--rowBorderColor': 'transparent',
    },
    '& .MuiDataGrid-columnHeader:focus': {
        outline: 'none',
    },
}));

const StyledTableHeader = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const StyledTextField = styled(TextField)`
    margin-bottom: 1rem;
`;
const StyledHeaderToolbar = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0.5rem 1rem;
    background-color: ${EColors.aliceBlue};
`;

const StyledIconButton = styled(IconButton)`
    padding-left: 0;
`;

const StyledActionButton = styled(Button)`
    &.MuiButton-root {
        text-transform: none;
    }
`;

type Row = {
    id: string;
    name: string;
    email: string;
    phone: string;
    status: string;
};

type GuestListTableProps = {
    rows: Row[];
    onEdit: (id: string) => void;
    onSortChange: (newValue: string) => void;
    editable: boolean;
    onEditClick: (id: string) => void;
    handleSelectAttendees: (ids: string[]) => void;
    onMultiDeleteAttendeesClick: () => void;
};

type GuestListTableToolbarProps = {
    value: string;
    handleSearch: (val: string) => void;
};

type CustomGuestListHeaderToolbarProps = {
    rowSelectionModel: GridRowSelectionModel;
    handleDelete: () => void;
};

const CustomGuestListHeaderToolbar = (props: CustomGuestListHeaderToolbarProps) => {
    const { rowSelectionModel, handleDelete } = props;
    const isDisabled = rowSelectionModel.length === 0;

    return (
        <StyledHeaderToolbar>
            <StyledIconButton disabled={isDisabled} onClick={handleDelete}>
                <GridDeleteIcon color={isDisabled ? 'disabled' : 'action'} />
            </StyledIconButton>
            {!isDisabled && (
                <Typography fontSize={14} sx={{ ml: 1 }}>
                    {rowSelectionModel.length} selected
                </Typography>
            )}
        </StyledHeaderToolbar>
    );
};

const GuestListTableToolbar = (props: GuestListTableToolbarProps) => {
    const { value, handleSearch } = props;

    return (
        <StyledTextField
            variant="standard"
            InputProps={{
                startAdornment: <GridSearchIcon />,
            }}
            value={value}
            placeholder="Search..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e.target.value)}
        />
    );
};

export const GuestListTable = (props: GuestListTableProps) => {
    const { rows, onEditClick, handleSelectAttendees, onMultiDeleteAttendeesClick } = props;

    const columns: GridColDef[] = useMemo(
        () => [
            {
                field: 'name',
                headerName: 'Name',
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => row.name,
            },
            {
                field: 'email',
                headerName: 'Email',
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => row.email,
            },
            {
                field: 'phone',
                headerName: 'Phone number',
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => row.phone,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: ({ row }: GridRenderCellParams) => row.status,
            },
            {
                field: 'action',
                headerName: 'Action',
                flex: 1,
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ row }: GridRenderCellParams) => (
                    <StyledActionButton
                        variant="text"
                        onClick={() => {
                            onEditClick(row.id);
                        }}
                    >
                        {i18n.button.view}
                    </StyledActionButton>
                ),
            },
        ],
        [onEditClick]
    );

    const [searchTerm, setSearchTerm] = useState('');
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    const hasRowsSelected = rowSelectionModel.length > 0;

    const handleSearch = useCallback((val: string) => {
        setSearchTerm(val);
    }, []);

    const handleSelectionChange = useCallback(
        (ids: string[]) => {
            setRowSelectionModel(ids);
            handleSelectAttendees(ids);
        },
        [handleSelectAttendees]
    );

    const filteredRows = useMemo(
        () =>
            rows.filter(
                row =>
                    row.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    row.phone?.includes(searchTerm)
            ),
        [rows, searchTerm]
    );

    return (
        <>
            <StyledTableHeader>
                <GuestListTableToolbar value={searchTerm} handleSearch={handleSearch} />
            </StyledTableHeader>
            <StyledDataGridPro
                rows={filteredRows}
                columns={columns}
                autoHeight
                checkboxSelection
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                disableColumnResize
                disableColumnSelector
                hideFooter
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={rowSelectionModel}
                slots={{
                    toolbar: hasRowsSelected && CustomGuestListHeaderToolbar,
                }}
                slotProps={{
                    toolbar: {
                        rowSelectionModel: rowSelectionModel,
                        handleDelete: onMultiDeleteAttendeesClick,
                    },
                }}
            />
        </>
    );
};
